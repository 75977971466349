import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ICopyEventToSeasonDto } from '@model/interfaces/custom/copy-event-to-season-dto';
import { IEventApproveEventPayload } from '@model/interfaces/custom/event-approve-event-payload';
import { IEventQualificationLevelDto } from '@model/interfaces/custom/event-qualification-level-dto';
import { BaseService } from '@mt-ng2/base-service';
import { Observable, Subject } from 'rxjs';
import { IEvent } from '../../../model/core/interfaces/event';
import { IEventQualificationLevel } from '@model/interfaces/event-qualification-level';
import { IQualificationLevel } from '@model/interfaces/qualification-level';
import { ISpotAssignmentEventDTO } from '@model/interfaces/custom/spot-assignment-event-dto';
import { ITeam } from '@model/interfaces/team';
import { IEventWaitlist } from '@model/interfaces/event-waitlist';
import { IAddress } from '@model/interfaces/address';
import { ITeamAvailableSpotsDto } from '@model/interfaces/custom/team-available-spots-dto';
import { IRegion } from '@model/interfaces/region';

export const emptyEvent: IEvent = {
    Name: '',
    AdditionalContactInformation: '',
    IsInvitationalOnly: false,
    RegistrationEmailBody: '',
    EventTypeId: 0,
    EventStatusId: 0,
    EventNumber: 0,
    Archived: false,
    Id: 0,
    SeasonId: 0,
    CreatedById: 0,
    Timezone: '',
    Code: '',
    Subtitle: '',
    StartDate: null,
    EndDate: null,
    ReleaseDate: null,
    RegistrationStartDate: null,
    RegistrationEndDate: null,
    DateCreated: null,
    AwardsFinalized: false,
};

@Injectable({
    providedIn: 'root',
})
export class EventService extends BaseService<IEvent> {
    constructor(public http: HttpClient) {
        super('/events', http, [], { entityName: 'Events' });
    }

    private eventQualificationLevelChangedSource = new Subject<void>();
    eventQualificationLevelChanged = this.eventQualificationLevelChangedSource.asObservable();
    emitQualificationLevelChanged = () => this.eventQualificationLevelChangedSource.next();

    getEmptyEvent(): IEvent {
        return { ...emptyEvent };
    }

    copyEventToSeason(dto: ICopyEventToSeasonDto): Observable<number> {
        return this.http.post<number>(`/events/copy-to-season`, dto);
    }

    approveEvent(id: number, payload: IEventApproveEventPayload): Observable<void> {
        return this.http.put<void>(`/events/${id}/approve-event`, payload);
    }

    readyForApproval(eventId: number) {
        return this.http.post<number>(`/events/ready-for-approval/${eventId}`, {});
    }

    saveAdditionalContactInformation(eventId: number, additionalContactInfo: string): Observable<void> {
        return this.http.post<void>(`/events/additional-contact-information/${eventId}`, { AdditionalContactInformation: additionalContactInfo });
    }

    private refreshEventListSubject = new Subject<void>();
    refreshEventListSubject$ = this.refreshEventListSubject.asObservable();
    refreshEventList() {
        this.refreshEventListSubject.next();
    }

    private showCopyEventModalSubject = new Subject<IEvent>();
    showCopyEventModalSubject$ = this.showCopyEventModalSubject.asObservable();
    showCopyEventModal(event: IEvent) {
        this.showCopyEventModalSubject.next(event);
    }

    userHasAccess(eventId: number): Observable<boolean> {
        return this.http.get<boolean>(`/events/${eventId}/user-has-access`);
    }

    private refreshMenuLinkClassSubject = new Subject<IEvent>();
    refreshMenuLinkClassSubject$ = this.refreshMenuLinkClassSubject.asObservable();
    refreshMenuLinkClass(event: IEvent) {
        this.refreshMenuLinkClassSubject.next(event);
    }

    saveEventQualificationLevel(Id: number, data: IEventQualificationLevelDto): Observable<IEventQualificationLevel> {
        return this.http.post<IEventQualificationLevel>(`/events/${Id}/qualification-levels`, data);
    }

    removeQualifiedQualificationLevel(eventId: number, qualificationLevelid: number): Observable<void> {
        return this.http.delete<void>(`/events/${eventId}/qualification-levels/${qualificationLevelid}`);
    }

    addQualifiedQualificationLevel(eventId: number, qualificationLevelid: number): Observable<void> {
        return this.http.post<void>(`/events/${eventId}/qualification-levels/${qualificationLevelid}`, {});
    }

    getQualifiedQualificationLevels(eventId: number): Observable<IQualificationLevel[]> {
        return this.http.get<IQualificationLevel[]>(`/events/${eventId}/qualification-levels`);
    }

    getQualifiedEvents(eventId: number): Observable<IEvent[]> {
        return this.http.get<IEvent[]>(`/events/${eventId}/qualified-events`);
    }

    getSpotAssignmentEvents(eventId: number): Observable<ISpotAssignmentEventDTO[]> {
        return this.http.get<ISpotAssignmentEventDTO[]>(`/events/${eventId}/spot-assignment-events`);
    }

    addQualifiedEvent(eventId: number, qualifiedEventId: number): Observable<ISpotAssignmentEventDTO> {
        return this.http.post<ISpotAssignmentEventDTO>(`/events/${eventId}/spot-assignment-events/${qualifiedEventId}`, {});
    }

    removeQualifiedEvent(Id: number, qualifiedEventId: number): Observable<void> {
        return this.http.delete<void>(`/events/${Id}/spot-assignment-events/${qualifiedEventId}`);
    }

    updateQualifiedEvent(Id: number, event: ISpotAssignmentEventDTO) {
        return this.http.put<void>(`/events/${Id}/spot-assignment-events/${event.QualifiedEventId}`, event);
    }

    getOpenEvents(): Observable<IEvent[]> {
        return this.http.get<IEvent[]>(`/events/get-open-events`);
    }

    getRegisteredTeams(eventId: number): Observable<number[]> {
        return this.http.get<number[]>(`/events/${eventId}/get-registered-teams`);
    }

    getTeamAllowedToBeAddedToWhitelist(eventId: number): Observable<ITeam[]> {
        return this.http.get<ITeam[]>(`/events/${eventId}/get-teams-allowed-to-be-added-to-waitlist`);
    }

    getTeamIdsAllowedToRegister(eventId: number): Observable<number[]> {
        return this.http.get<number[]>(`/events/${eventId}/get-team-ids-allowed-to-register`);
    }

    getTeamsAllowedToRegister(eventId: number): Observable<ITeam[]> {
        return this.http.get<ITeam[]>(`/events/${eventId}/get-teams-allowed-to-register`);
    }

    getAvailableSpots(eventId: number): Observable<number> {
        return this.http.get<number>(`/events/${eventId}/get-available-number-spots`);
    }

    getWaitlistsForEvent(eventId: number): Observable<IEventWaitlist[]> {
        return this.http.get<IEventWaitlist[]>(`/events/${eventId}/waitlists`);
    }

    getAvailableSpotsPerTeam(eventId: number): Observable<ITeamAvailableSpotsDto[]> {
        return this.http.get<ITeamAvailableSpotsDto[]>(`/events/${eventId}/available-spots-per-team`);
    }

    checkForRegionChange(event: IEvent): Observable<boolean> {
        return this.http.post<boolean>(`/events/${event.Id}/check-for-region-change`, event);
    }

    getEventById(eventId: number): Observable<IEvent> {
        return this.http.get<IEvent>(`/events/${eventId}/get-by-id`);
    }

    getEventDetail(eventId: number): Observable<IEvent> {
        return this.http.get<IEvent>(`/events/${eventId}/event-detail`);
    }

    isEventAdmin(eventId: number): Observable<boolean> {
        return this.http.get<boolean>(`/events/${eventId}/is-event-admin`);
    }

    getApprovedEvents(): Observable<IEvent[]> {
        return this.http.get<IEvent[]>(`/events/get-approved-events`);
    }

    getEventQualifyingTeamRegions(eventId: number): Observable<IRegion[]> {
        return this.http.get<IRegion[]>(`/events/${eventId}/qualifying-team-regions`);
    }

    getEventQualifyingEventRegions(eventId: number): Observable<IRegion[]> {
        return this.http.get<IRegion[]>(`/events/${eventId}/qualifying-event-regions`);
    }

    isEventPartner(eventId: number): Observable<boolean> {
        return this.http.get<boolean>(`/events/${eventId}/is-event-partner`);
    }

    getEventSection(eventId: number): Observable<IEvent> {
        return this.http.get<IEvent>(`/events/${eventId}/get-event-section`);
    }

    cancelEvent(eventId: number): Observable<void> {
        return this.http.get<void>(`/events/${eventId}/cancel-event`);
    }

    getInvitedTeams(eventId: number): Observable<ITeam[]> {
        return this.http.get<ITeam[]>(`/events/${eventId}/get-invited-teams`);
    }
}
